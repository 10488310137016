import { createStore } from "redux";
import reducer from "../reducers";
import { loadState, saveState } from "../../helper/localStorage";
import throttle from "lodash/throttle";

const persistedState = loadState();

const windowGlobal = typeof window !== "undefined" && window;

const devtools =
  process.env.NODE_ENV === "development"
    ? windowGlobal.__REDUX_DEVTOOLS_EXTENSION__ &&
      windowGlobal.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f;

const store = createStore(reducer, persistedState, devtools);

store.subscribe(
  throttle(() => {
    saveState({
      user: store.getState()?.user,
    });
  }, 1000)
);

export default store;
