import { ADD_MESSAGE, REMOVE_MESSAGE } from "../actionTypes/message";

const initialState = null;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return action.payload;
    case REMOVE_MESSAGE:
      return null;
    default:
      return state;
  }
};

export default reducer;
