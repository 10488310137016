import {
  ADD_RENEWAL_QUOTE,
  SET_RENEWAL_QUOTE,
} from "../actionTypes/renewQuotes";

const initialState = { items: [] };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RENEWAL_QUOTE:
      return {
        ...state,
        items: action.payload,
      };
    case ADD_RENEWAL_QUOTE:
      return {
        ...state,
        items: [...state, action.payload],
      };
    default:
      return state;
  }
};

export default reducer;
