import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import stellantisTheme from "../../themes/stellantis";
import publicTheme from "../../themes/public";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
  },
}));

const TopLayout = ({ children }) => {
  const classes = useStyles();

  switch (process.env.GATSBY_BRANDING) {
    case "stellantis":
      return (
        <ThemeProvider theme={stellantisTheme}>
          <CssBaseline />
          <div className={classes.root}>{children}</div>
        </ThemeProvider>
      );
    case "public":
      return (
        <ThemeProvider theme={publicTheme}>
          <CssBaseline />
          <div className={classes.root}>{children}</div>
        </ThemeProvider>
      );
    default:
      return <>FEHLER: Es wurde kein branding in der .env Datei angegeben! </>;
  }
};

export default TopLayout;
