import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

const theme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: "Calibri, serif",
      fontWeight: "100",
    },
    palette: {
      primary: {
        main: "#144c44",
        light: "#fff",
        dark: "#000",
      },
    },
  })
);

export default theme;
