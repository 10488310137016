import { SET_QUOTES, ADD_QUOTE } from "../actionTypes/quotes";

const initialState = { items: [], loading: false };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_QUOTES:
      return {
        ...state,
        items: action.payload,
        loading: true,
      };
    case ADD_QUOTE:
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    default:
      return state;
  }
};

export default reducer;
