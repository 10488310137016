//ReduxWrapper.js
import React from "react";
import { Provider } from "react-redux";
import store from "./store";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";

const instance = createInstance({
  urlBase: "https://admin.thg-quoten.com/",
  siteId: process.env.GATSBY_MATOMO_SITE_ID,
  // userId: "UID76903202", // optional, default value: `undefined`.
  // trackerUrl: "https://LINK.TO.DOMAIN/tracking.php", // optional, default value: `${urlBase}matomo.php`
  // srcUrl: "https://LINK.TO.DOMAIN/tracking.js", // optional, default value: `${urlBase}matomo.js`
  // disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  // heartBeat: {
  //   // optional, enabled by default
  //   active: true, // optional, default value: true
  //   seconds: 10, // optional, default value: `15
  // },
  // linkTracking: false, // optional, default value: true
  // configurations: {
  //   // optional, default value: {}
  //   // any valid matomo configuration, all below are optional
  //   disableCookies: true,
  //   setSecureCookie: true,
  //   setRequestMethod: "POST",
  // },
});

const ReduxWrapper = ({ element }) => (
  <MatomoProvider value={instance}>
    <Provider store={store}>{element}</Provider>
  </MatomoProvider>
);

export default ReduxWrapper;
