import { SET_PRICES, GET_PRICES } from "../actionTypes/prices";

const initialState = { items: [] };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRICES:
      return {
        ...state,
        items: action.payload,
      };
    case GET_PRICES:
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    default:
      return state;
  }
};

export default reducer;
