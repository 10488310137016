import {
  SET_USER,
  RESET_USER,
  UPDATE_USER_INFO,
  UPDATE_ACCOUNT_INFO,
  UPDATE_ISCOMPANY,
  UPDATE_SUBSCRIPTION,
} from "../actionTypes/user";

const initialState = {
  token: null,
  info: {},
  account: {},
  isCompany: {},
  subscription: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SUBSCRIPTION:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          ...action.payload,
        },
      };
    case UPDATE_ISCOMPANY:
      return {
        ...state,
        isCompany: {
          ...state.isCompany,
          ...action.payload,
        },
      };
    case UPDATE_ACCOUNT_INFO:
      return {
        ...state,
        account: {
          ...state.account,
          ...action.payload,
        },
      };
    case UPDATE_USER_INFO:
      return {
        ...state,
        info: {
          ...state.info,
          ...action.payload,
        },
      };
    case SET_USER:
      const { token, account_holder, iban, ...rest } = action.payload;
      return {
        token,
        info: rest,
        account: {
          account_holder,
          iban,
        },
      };
    case RESET_USER:
      return {};
    default:
      return state;
  }
};

export default reducer;
