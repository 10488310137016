import { combineReducers } from "redux";
import quotes from "./quotes";
import user from "./user";
import message from "./message";
import prices from "./prices";
import renewQuotes from "./renewQuotes";
import renewalPrices from "./renewalPrices";

export default combineReducers({
  quotes,
  user,
  message,
  prices,
  renewQuotes,
  renewalPrices,
});
